@tailwind base;
@tailwind components;
@tailwind utilities;

#filter::-webkit-scrollbar {
  height: 2px;
}

.activity {
  background-image: url("../public/bg-news.png");
  background-repeat: no-repeat;
  background-position: center;
}

#navbar {
  font-family: "times", sans-serif;
}

#background {
  background-image: url("../public/background.png");
  background-attachment: fixed;
}

.slickSlide {
  width: 300px !important;
  margin: 0 25px;
  background-color: white;
  border: 1px solid black;
  height: 550px;
}

.slickSideOther {
  height: 400px !important;
}

.slickSlide:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.productParent {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

@media only screen and (max-width: 800px) {
  .productParent {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 540px) {
  .productParent {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* For Webkit-based browsers */
::-webkit-scrollbar {
  width: 8px; /* width of the scrollbar */
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* color of the track */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #223a72; /* color of the handle */
  border-radius: 6px; /* roundness of the handle */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* color of the handle on hover */
}

.searchInput:focus {
  outline: none;
  border: 1px solid #223a72;
}

.iconWrapper {
  background-color: #223a72;
  padding: 12px;
  border-radius: 56% 24% 73% 27% / 45% 68% 32% 55%;
}

.noscrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.background {
  background-image: url("../public/bg-news-1.png");
  background-position: center bottom 0%;
  background-repeat: no-repeat;
}

.userbg {
  background-image: url("../public/web-bg.png");
  background-repeat: no-repeat;
  background-position: center;
}

.MuiTablePagination-toolbar,
.MuiDataGrid-footerContainer {
  display: none !important;
}


.react-multi-carousel-list{
  position: unset !important;
}