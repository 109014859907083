@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap");

.robot {
  font-family: "Roboto Slab", serif;
}

@font-face {
  font-family: "Light";   /*Can be any text*/
  src: local("times"),
    url("../public/times.ttf") format("truetype");
}

body {
  /* color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb)); */
}
